@import 'components/mixins.scss';

.footer {
  margin: 0 auto;
}

.footerInner {
  border-top: 1px solid $border;
  padding: rem(13) !important;
}

.logo {
  color: $black;
  font-size: rem(17);
  text-transform: uppercase;
  letter-spacing: -1px;
  font-weight: 900;

  span {
    display: inline-block;
    height: rem(13);
    width: rem(13);
    background: $black;
    border-radius: 20px;
    margin-left: rem(8);
    vertical-align: middle;
    position: relative;
    top: -2px;
  }
}

// dark theme
[data-kit-theme='dark'] {
  .footer {
    color: $dark-gray-1;
  }

  .footerInner {
    border-top: 1px solid $dark-gray-4;
  }

  .logo {
    span {
      background: $dark-gray-1;
    }
  }
}
